<template>
  <div class="loading-box">
	  <div class="loadingText">{{loadingText}}</div>
    <i class="el-icon-loading"></i>
  </div>
</template>

<script>
export default {
name:'loadingBox',
props:['loadingText']
}
</script>

<style lang="scss" scoped>
.loading-box{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.03);
  font-size: .48rem;
  color: #1E9E85;
  .loadingText{
	  font-size: 0.24rem;
  }
}
</style>